// import React, { useState, useEffect } from 'react';
// import Joyride from 'react-joyride';
// import styled from 'styled-components';
// import EE from '../../../api/eventemitter'

// const Ride = () => {
//     const [runTour, setRunTour] = useState(false);
//     const steps = [
//         {},
//     ];

//     useEffect(()=>{
//         EE.on('Game Joyride',()=>{
//             setRunTour(true)
//         })

//         return () => {
//             EE.off('Game Joyride')
//         }
//     },[]);

//     return(
//         <Joyride
//             steps={steps}
//             run={runTour}
//             continuous
//             scrollToFirstStep
//             showSkipButton
//             spotlightPadding={10}
//             styles={{
//                 options: {
//                     primaryColor: '#6366f1',
//                     textColor: '#333',
//                 },
//                 buttonNext:{
//                     backgroundColor: '#6366f1',
//                     color: '#fff',
//                 },
//                 buttonSkip:{
//                     color: '#888'
//                 },
//             }}
//         />
//     )
// }

// export default Ride


import React, { useState, useEffect } from 'react';
import '../../../app/app.css'; // External styles for positioning and highlighting
import EE from '../../../api/eventemitter'
import t1 from '../../../element/2024feb/t1.png'
import t2 from '../../../element/2024feb/t2.png'
import t3 from '../../../element/2024feb/t3.png'
import t4 from '../../../element/2024feb/t4.png'
import t5 from '../../../element/2024feb/t5.png'
import t6 from '../../../element/2024feb/t6.png'

const tutorialSteps = [
  {
    target: '.canvas-select',
    image: t1,
    title: 'Welcome, Ray',
    description: 'This is a canvas, a playing-card collaboration spac for a jam session with an end goal.',
    pos: 'mid',
  },
  {
    target: '.canvas-select',
    image: t2,
    title: 'Navigating the Canvas',
    description: 'Click + / - to zoom. To move the canvas, press the "Select" tool to switch.',
    pos: 'left',
  },
  {
    target: '.game-hand',
    image: t3,
    title: `What's in your Hand?`,
    description: 'Everything from a private space to wrtie and even receive surprise cards',
    pos: 'right',
  },
  {
    target: '.canvas-select',
    image: t4,
    title: 'Flip for suspense!',
    description: 'Tips: Before dragging card from Hand to Canvas, select Flip (from card menu) to hide',
    pos: 'mid',
  },
  {
    target: '.canvas-select',
    image: t5,
    title: 'Ready, set, drag!',
    description: `Once you're finished writing in Hand panel, click + drag the card to the canvas.`,
    pos: 'mid',
  },
  {
    target: '.canvas-select',
    image: t6,
    title: 'Pair any sides!',
    description: 'To respond or add a definition to a card, move your card to any of the sides to activate pair',
    pos: 'mid',
  },
  // Additional steps can be added here
];

const Ride = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [tutorialActive, setTutorialActive] = useState(false);

      useEffect(()=>{
        // if(window.location.hash === '#tutorial'){
        //     setTutorialActive(true);
        //     setCurrentStep(0);
        // }

        EE.on('Game Joyride',()=>{
            setTutorialActive(true)
        })

        return () => {
            EE.off('Game Joyride',()=>{
                setTutorialActive(false)
            })
        }
    },[]);

  const nextStep = () => {
    if (currentStep < tutorialSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      setTutorialActive(false); // End tutorial
    }
  };

  const skipTutorial = () => {
    setTutorialActive(false);
  };

  const startTutorial = () => {
    setTutorialActive(true);
    setCurrentStep(0); // Start from the first step
  };

  const step = tutorialSteps[currentStep];

  return (
    <div className="ride">
      {/* Your UI elements */}
      {/* Tutorial Overlay */}
      {tutorialActive && (
        <div className="tutorial-overlay">
          <div className="highlight" style={getHighlightStyles(step.target)}></div>
          <div style={getBoxStyle(step.pos)} className="tutorial-box">
            <div className="left">
              <img src={step.image} alt="Tutorial" />
            </div>
            <div className="right">
              <h4>{step.title}</h4>
              <p>{step.description}</p>
              <p>Step {currentStep + 1} of {tutorialSteps.length}</p>
              <div className="actions">
                <button onClick={skipTutorial}>Skip</button>
                <button onClick={nextStep}>Next</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// Function to get the bounding box of the element to highlight
const getHighlightStyles = (target) => {
  const element = document.querySelector(target);
  //console.log(element)
  if (element) {
    const rect = element.getBoundingClientRect();
    // console.log(rect)
    return {
      position: 'absolute',
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
      width: rect.width,
      height: rect.height,
      border: '2px solid #6366F1',
      zIndex: 1000,
    };
  }
  return {};
};

const getBoxStyle = (pos) => {
    if(pos === "mid"){
        return
    }

    if(pos === "left"){
        return{
            position: 'absolute',
            left: '100px',
            top: '100px',
        }
    }

    if(pos === "right"){
        return{
            position: 'absolute',
            right: '250px',
            top: '100px',
        }
    }
}


export default Ride 